import { createContext } from "react";

type Country = {
  name: string;
  label: string;
  value: string;
};

type CountriesContextType = {
  countries: Country[];
};

export const CountriesContext = createContext<CountriesContextType>({
  countries: [],
});
