import React, { ReactNode } from "react";
import { useState } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import Tab from "@/tab";
import { Card } from "@/ui/card";

import {
  getGetGeneralConfigV1GeneralConfigGetSuspenseQueryOptions,
  useUpdateGeneralConfigV1GeneralConfigPatch,
} from "~/api/general-config/general-config.gen";
import { AntiCheatConfigSubModel } from "~/api/model";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { Form } from "~/oldComponents/form/Form";
import { Field } from "~/oldComponents/form/types";
import { useAuth } from "~/providers/auth";

export const Route = createFileRoute("/_auth/general-config/anti-cheat")({
  loader: ({ context: { queryClient } }) => {
    return queryClient.ensureQueryData(getGetGeneralConfigV1GeneralConfigGetSuspenseQueryOptions());
  },
  component: () => <GeneralConfigPage />,
});

function GeneralConfigPage() {
  const { roles } = useAuth();

  const { data: generalConfig, refetch } = useSuspenseQuery(getGetGeneralConfigV1GeneralConfigGetSuspenseQueryOptions());
  const [antiCheat, setAntiCheat] = useState<AntiCheatConfigSubModel>(generalConfig.antiCheat);

  const updateGeneralConfigMutation = useUpdateGeneralConfigV1GeneralConfigPatch({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
      onSuccess: () => {
        toast("Config was successfully updated!");
        refetch();
      },
    },
  });

  const onSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    await updateGeneralConfigMutation.mutateAsync({ data: { ...generalConfig, antiCheat: antiCheat } });
  };

  const antiCheatFields: Field[] = [
    {
      name: "dailySteps",
      label: "Daily steps amount",
      helpText:
        "Automatic check of the number of steps for the previous day.\nIf the user has more steps than the provided value, he will be marked as a possible cheater.",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        setAntiCheat((prev) => ({
          ...prev,
          dailySteps: +event.target.value,
        }));
      },
      value: antiCheat.dailySteps,
      min: 1,
    },
    {
      name: "weeklySteps",
      label: "Weekly steps amount",
      helpText:
        "Automatic check of the number of steps per day for the previous eight days.\nIf the user has more steps than the provided value, he will be marked as a possible cheater.",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        setAntiCheat((prev) => ({
          ...prev,
          weeklySteps: +event.target.value,
        }));
      },
      value: antiCheat.weeklySteps,
      min: 1,
    },
    {
      name: "fortyKRewards",
      label: "40K rewards per month",
      helpText:
        "Automatic check of the number of 40k reward that user claimed for the previous 30 days.\nIf the user has more claims than the provided value, he will be marked as a possible cheater.",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        setAntiCheat((prev) => ({
          ...prev,
          fortyKRewards: +event.target.value,
        }));
      },
      value: antiCheat.fortyKRewards,
      min: 1,
    },
    {
      name: "inactiveInvitees",
      label: "User inactive invitees",
      helpText:
        "Automatic check of the number of user inactive invitees for the previous 30 days.\nIf the user has more inactive invitees than the provided value, he will be marked as a possible cheater.",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        setAntiCheat((prev) => ({
          ...prev,
          inactiveInvitees: +event.target.value,
        }));
      },
      value: antiCheat.inactiveInvitees,
      min: 1,
    },
    {
      name: "userInactiveDays",
      label: "User inactive days",
      helpText:
        "Service field for checking user activity. User will be considered as inactive if he didn't visited app for the provided number of days. Used for checking inactive invitees.",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        setAntiCheat((prev) => ({
          ...prev,
          userInactiveDays: +event.target.value,
        }));
      },
      value: antiCheat.userInactiveDays,
      min: 1,
    },
  ];

  return (
    <section className="flex flex-col gap-10 bg-background px-8 py-10">
      <section className="flex items-start justify-between">
        <div className="header-titles_wrapper gap-4">
          <h2 className="text-foreground">Edit General Config</h2>
        </div>
      </section>
      <div>
        <div className="flex w-full gap-2">
          <Tab active={false} to={"/general-config"} title="Rewards" />
          <Tab active={false} to={"/general-config/offer"} title="Offer" />
          <Tab active={true} to={"/general-config/anti-cheat"} title="Anti Cheat" />
          <Tab active={false} to={"/general-config/auto-claim"} title="Auto claim" />
        </div>
        <Card className="bg-card px-4 py-10 text-card-foreground">
          <Form
            readonly={!roles.includes("Admin_Owner")}
            vertical={true}
            name="anti-cheat"
            fields={antiCheatFields}
            onSubmit={onSubmit}
            submitText="Save Anti-cheat config"
          />
        </Card>
      </div>
    </section>
  );
}
