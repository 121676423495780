import React from "react";
import { memo } from "react";
import { useRouterState } from "@tanstack/react-router";

import { BackendSelector } from "@/backend_change";
import { Icon } from "@/icon/icon";
import { ScrollArea } from "@/ui/scroll-area";

import { CustomRoute, externalRoutes, Route, routes } from "../../routes_old";

import { Nestedroute } from "./NestedRoute";
import { RoutedLink } from "./RoutedLink";

import { useAuth } from "~/providers/auth";
import { useTheme } from "~/providers/theme";

type Props = {
  menuOpened: boolean;
};

export const Sidebar = memo(({ menuOpened }: Props) => {
  const { logout, user } = useAuth();
  const { theme, setTheme } = useTheme();
  const router = useRouterState();
  const params = router.matches[router.matches.length - 1].params as { market: string };

  const extraRoutes: Array<CustomRoute | Route> = [
    {
      name: "My Profile",
      role: "ANY",
      icon: "FaClipboardUser",
      to: `/users/${user?.user_id}`,
    },
    {
      name: "Change Theme",
      role: "ANY",
      icon: theme === "light" ? "Sunny" : "DarkMode",
      onClick: () => setTheme(theme === "light" ? "dark" : "light"),
    },
    {
      name: `Version: ${APP_VERSION}`,
      role: "ANY",
      icon: "FaCodeBranch",
    },
  ];

  const getRoute = (route: Route | CustomRoute, index: number) => {
    // If the route has nested routes, render the nested route component
    if ("nested" in route && route.nested?.length) {
      return <Nestedroute key={`nested-route-${index}`} menuOpened={menuOpened} route={route} />;
    }
    return <RoutedLink key={`routed-link-${index}`} route={route} menuOpened={menuOpened} />;
  };

  return (
    <ScrollArea className="flex h-pageHeight flex-col gap-2 bg-sidebar">
      {routes.map((route, index) => {
        if (route.name === "Marketplace") route.to = route.to.replace("PLACE_MARKET", params.market ? params.market : user?.country || "SE");
        return getRoute(route, index);
      })}
      <hr></hr>
      {externalRoutes.map((route, index) => {
        return getRoute(route, index);
      })}
      <hr></hr>
      {extraRoutes.map((route, index) => {
        return getRoute(route, index);
      })}
      <div
        className={`flex ${menuOpened ? "gap-2" : "group whitespace-nowrap"} items-center border-l-8 border-transparent bg-sidebar hover:relative hover:border-primary`}
        title="Logout"
        onClick={logout}
      >
        <div className="px-4 py-5">
          <Icon icon="LogOut" />
        </div>

        <div className={`bg-sidebar ${menuOpened ? "py-4 pr-4" : "hidden p-4 group-hover:block"} text-sm`}>Logout</div>
      </div>
      <BackendSelector className="mx-2 text-xs" />
    </ScrollArea>
  );
});
Sidebar.displayName = "Sidebar";
