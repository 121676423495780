import React, { ReactNode } from "react";
import { createFileRoute } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import {
  useGetMarketsConfigV1MarketConfigGetSuspense,
  useUpdateMarketConfigV1MarketConfigMarketConfigIdPatch,
} from "~/api/market-config/market-config.gen";
import { MarketConfigModelOutput, MarketRewardsConfigSubModel, SocialMediaSubModel } from "~/api/model";
import { getCountryName } from "~/helpers/country-list";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { Checkbox } from "~/oldComponents/form/Checkbox";
import { ListPage } from "~/oldComponents/layout/ListPage";
import { PageConfig, TableConfig } from "~/oldComponents/layout/types";
import { useAuth } from "~/providers/auth";

export const Route = createFileRoute("/_auth/markets/")({
  component: () => <MarketConfigPage />,
});

const MarketConfigPage = () => {
  const navigate = Route.useNavigate();
  const { roles } = useAuth();

  const { data: markets, isFetching } = useGetMarketsConfigV1MarketConfigGetSuspense({
    query: { initialData: [] },
  });

  const updateMarketMutation = useUpdateMarketConfigV1MarketConfigMarketConfigIdPatch({
    mutation: {
      onSuccess: () => {
        toast("Market changed successfully!");
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const onStatusChange = async (market: MarketConfigModelOutput) => {
    await updateMarketMutation.mutateAsync({
      marketConfigId: market._id as string,
      data: {
        readyForRelease: !market.readyForRelease,
      },
    });
  };

  const page: PageConfig = {
    title: "Markets config",
    description: "Create new or edit current markets",
    addContent: "Create new market",
    addLink: "/markets/create",
    tableUpload: {
      fileName: "markets-config",
      data: markets,
    },
  };

  const table: TableConfig = {
    tableBuild: [
      {
        headerTitle: "Country code",
        format: (item: MarketConfigModelOutput) => `${item.countryCode} (${getCountryName(item.countryCode)})`,
      },
      {
        headerTitle: "Rewards",
        format: (item: MarketConfigModelOutput) => (
          <>
            {item.rewards.map((reward: MarketRewardsConfigSubModel, i: number) => (
              <p key={i}>
                {!["AcceptInvite", "AppleAttachment", "FacebookAttachment", "GoogleAttachment", "UserInvite"].includes(reward.source) &&
                  `${reward.source}:${reward.amount}`}
              </p>
            ))}
          </>
        ),
      },
      {
        headerTitle: "Social Links",
        format: (item: MarketConfigModelOutput) => (
          <>
            {item.socialMedia.map((social: SocialMediaSubModel, i: number) => (
              <p key={i}>{social.url}</p>
            ))}
          </>
        ),
      },
      {
        headerTitle: "Support emails",
        format: (item: MarketConfigModelOutput) => (
          <>
            {item.supportEmails.map((email: string, i: number) => (
              <p key={i}>{email}</p>
            ))}
          </>
        ),
      },
      {
        headerTitle: "Market banner",
        format: (item: MarketConfigModelOutput) => (item.bannerUrl ? "Downloaded" : "Missing"),
      },
      {
        headerTitle: "Release status",
        actionRow: true,
        format: (item: MarketConfigModelOutput) => {
          if (roles.includes("Admin_Owner")) {
            return <Checkbox checked={item.readyForRelease} onChange={onStatusChange.bind(onStatusChange, item)} />;
          }
          return item.readyForRelease ? "Published" : "Unpublished";
        },
      },
    ],
    objects: markets,
    link: (item: MarketConfigModelOutput) => {
      if (roles.includes("Admin_Owner")) {
        navigate({ to: "/markets/$marketId", params: { marketId: item._id as string } });
      }
    },
    emptyMessage: "There are no market configs in the system yet.",
  };

  return <ListPage loading={isFetching} page={page} table={table} readonly={!roles.includes("Admin_Owner")} />;
};
