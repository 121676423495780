import React, { Suspense } from "react";

import { CountriesContext } from "../context/context";
import { CountyList } from "../helpers/country-list";

import { useAuth } from "./auth";

import { AXIOS_INSTANCE } from "~/api/base";
import { useGetAvailableCountriesV1MarketConfigAvailableCountriesGet } from "~/api/market-config/market-config.gen";

type Props = {
  children?: React.ReactNode;
};

const CountriesProvider = ({ children }: Props) => {
  return (
    <Suspense fallback={<div></div>}>
      <CountriesContent>{children}</CountriesContent>
    </Suspense>
  );
};

const CountriesContent = ({ children }: Props) => {
  const { accessToken } = useAuth();

  if (accessToken) {
    AXIOS_INSTANCE.defaults.headers.Authorization = `Bearer ${accessToken}`;
  }

  const { data: countries } = useGetAvailableCountriesV1MarketConfigAvailableCountriesGet({
    query: {
      enabled: !!accessToken,
      initialData: [],
    },
  });

  const value = {
    countries:
      countries
        ?.sort()
        .map((value: string) => {
          const countryEntry = CountyList.find((element) => element.code === value);
          return {
            name: countryEntry?.name || value,
            label: countryEntry?.name || value,
            value: value,
          };
        })
        .filter((country) => country.value) || [],
  };

  return <CountriesContext.Provider value={value}>{children}</CountriesContext.Provider>;
};

export default CountriesProvider;
