import React from "react";
import { Link, ToPathOption } from "@tanstack/react-router";

function Tab({ active, to, title }: { active: boolean; to: ToPathOption; title: string }) {
  return (
    <Link
      className="rounded-t-lg bg-tab px-10 py-2.5 text-tab-foreground opacity-50 transition-opacity hover:opacity-70 data-[state=active]:opacity-100"
      data-state={active ? "active" : ""}
      to={to}
    >
      {title}
    </Link>
  );
}

export default Tab;
