import React from "react";
import { createFileRoute, Link } from "@tanstack/react-router";

import { Icon } from "@/icon/icon";
import { buttonVariants } from "@/ui/button";
import { Card, CardContent } from "@/ui/card";

import PageLayout from "~/oldComponents/layout/PageLayout";
import { useAuth } from "~/providers/auth";
import { Route as RouteType } from "~/routes_old";

export const Route = createFileRoute("/_auth/dashboard")({
  component: DashboardPage,
});

function DashboardPage() {
  const { user } = useAuth();

  const navConfig: RouteType[] = [
    { to: "/$market/marketplace", name: "Offers", icon: "MarketPlace", params: { market: user?.country || "SE" }, role: "ANY" },
    { to: "/users", name: "Users Search", icon: "Users", role: "ANY" },
    { to: "/markets", name: "Markets config", icon: "Settings", role: "ANY" },
  ];

  return (
    <PageLayout title="Dashboard" description="Quick navigation">
      <Card>
        <CardContent className="flex justify-around gap-4 p-4">
          {navConfig.map(({ to, name, icon, params }) => (
            <Link
              key={name}
              to={to}
              params={params}
              title={name}
              className={`${buttonVariants({ variant: "ghost", size: "giantIcon" })} text-center`}
            >
              <Icon icon={icon} className="h-32 w-32" />
            </Link>
          ))}
        </CardContent>
      </Card>
    </PageLayout>
  );
}
