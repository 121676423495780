import React from "react";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRouter, ErrorComponent, RouterProvider } from "@tanstack/react-router";

import ErrorPage from "./pages/Error/Error";
// import SplashPage from "./pages/Splash/Splash";
import { AuthProvider, useAuth } from "./providers/auth";
import { routeTree } from "./routeTree.gen";

import "./styles/reset.css";
import "./styles/index.css";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 2,
    },
  },
});

// Set up a Router instance
const router = createRouter({
  routeTree: routeTree,
  // defaultPendingComponent: () => <SplashPage />,
  defaultErrorComponent: ({ error }) => <ErrorComponent error={error} />,
  context: {
    auth: undefined!, // We'll inject this when we render
    queryClient: queryClient,
  },
  defaultPreload: "intent",
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
});

// Register things for typesafety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

function InnerApp() {
  const auth = useAuth();
  return (
    <RouterProvider
      router={router}
      defaultPreload="intent"
      context={{
        auth: auth,
        queryClient: queryClient,
      }}
    />
  );
}

function App() {
  return (
    <AuthProvider>
      <InnerApp />
    </AuthProvider>
  );
}

Sentry.init({
  dsn: import.meta.env.VITE_REACT_SENTRY_DSN,
  release: APP_VERSION,
  environment: import.meta.env.VITE_REACT_APP_NODE_ENV,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: Number(import.meta.env.VITE_REACT_SENTRY_TRACES_SAMPLE_RATE) || 0,
  replaysSessionSampleRate: 0, // For now disable. Since we can only have 50 a month
  replaysOnErrorSampleRate: 0, // For now disable. Since we can only have 50 a month
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>,
);
